  

.fullt{

  background-color: #fff;
 
}

  .tddiv  {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
  }
  
  .headicon {

    color: #1bbaaf;
  }
  
  .tlogo-container {
    margin-right: 5px;
  }


 
  .btn-primary {
    --bs-btn-color: none; 
    --bs-btn-border-color: none; 
    --bs-btn-hover-color: none; 
    --bs-btn-hover-bg: none; 
    --bs-btn-hover-border-color: none; 
    --bs-btn-focus-shadow-rgb: none; 
    --bs-btn-active-color: none; 
    --bs-btn-active-bg: none; 
    --bs-btn-active-border-color: none; 
    --bs-btn-active-shadow: none; 
    --bs-btn-disabled-color: none; 
    --bs-btn-disabled-bg: transparent; 
    --bs-btn-disabled-border-color: none; 
    --bs-gradient: none; 
}
  
  

  
 
 
  
.tcustom-dropdown-item {

  display: flex;
  
  text-decoration: none; 
  color: #1bbaaf;
  margin-top: 5px;
  font-size: 26px;
 
  background-color: #fff;
 
  border: none;
  
  z-index: 1; 

 }
  
 
.loglink:hover {

  color: black;
}
 











