      
      
      
      
      
      
      .specialities-section {
        padding: 20px;
        text-align: center;
        background-color:  #F9F8F8;
        margin-top: 40px;
        font-family:'Montserrat', sans-serif, Arial;
      }

      .specialities-section h2 {
        font-size: 62px;
        font-weight: 800;
        margin-bottom: 55px;
        

      }

      

      .arrow-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        cursor: pointer;
        color: black;
        font-weight: 600;
        padding: 0.5rem;
        
        z-index: 1;
      }

      .left-arrow {
        left: 0;
      }

      .right-arrow {
        right: 0;
      }


      
      
      .sp-bt.btn.btn-primary {
        --bs-btn-color: #fff;
        --bs-btn-bg: #1bbaaf;
        --bs-btn-border-color: #1bbaaf;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #008a80;
        --bs-btn-hover-border-color: #008a80;
        --bs-btn-focus-shadow-rgb: none;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #1bbaaf;
        --bs-btn-active-border-color: #1bbaaf;
        --bs-btn-active-shadow: none;
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: none;
        --bs-btn-disabled-border-color: none;


      }




      .sp-bt.btn {

      background-color:#1bbaaf;
      border-color: #1bbaaf;
      border-radius: 999px;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      margin-bottom: 20px;
      }
      

      .btn {

        --bs-btn-box-shadow: none;
        --bs-btn-focus-box-shadow: none;
       }
      

       
      .specialities-items-mobile {
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        margin-bottom: 20px;
        flex-wrap: nowrap;
        position: relative;
        font-weight: 500;
        font-size: 20px;
      }

      .specialities-items-mobile .specialities-item {
        flex: 0 0 100%;
        display: flex;
        
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        font-size: 20px;
        font-weight: 500;
        background-color: #f5f5f5;
        border-radius: 25px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
       
      }

      .specialities-items-mobile .specialities-item.active {
        transform: scale(1.05);
      }

      .speciality-icon {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
      }

     

      .specialities-items-desktop {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
      
        font-weight: 600;
      }

      .specialities-items-desktop .speciality-item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        
        border-radius: 5px;
      
        margin-bottom: 20px;
        margin-right: 45px;
        font-weight: 600;
      }

      .specialities-items-desktop .speciality-item:hover {

        background-color: #f0f0f0;
      }

      .specialities-items-desktop .speciality-item.active {
        transform: scale(1.05);
      }

      .specialty-icon {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
      }

          


      .specialities-name {
        font-size: 26px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .specialities-description {
        font-size: 20px;
        font-weight: 400;
        line-height: 0.7;
        margin-top: 15px;

      }

      .speciality-description {

        line-height: 0.7;
        margin-top: 18px;
      }

      .text-center {
        text-align: center;
      }

      .mt-4 {
        margin-top: 16px;
      }





      @media (max-width: 400px) {

        .arrow-icon{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.3rem;
          cursor: pointer;
          color: black;
          font-weight: 600;
          padding: 0.5rem;
          
          z-index: 1;
        }
  
        .left-arrow {
          left: -8px;
          
        }
  
        .right-arrow {
          right: -7px;
        }

          
        .specialities-section h2 {

          font-size: 35px;
         
      }

      .service-item {
        width: 50%;
      }


      .speciality-name {
        font-size: 16px;
        
      }

       .speciality-description {
        font-size: 10px;
       
      } 

      }

      @media (min-width: 400px) and (max-width: 767px) {

          
        .specialities-section h2 {

          font-size: 38px;
          font-weight: 800;

      }

      .service-item {
        width: 50%;
      }

      .speciality-name {
        font-size: 19px;
        
      }

       .speciality-description {
        font-size: 13px;
       
      } 

      }


      @media (min-width: 768px) and (max-width: 1200px) {

        .specialities-section h2 {

        font-size: 65px;
        font-weight: 800;

      }

      .sp-bt.btn {

        width: 340px;
        height: 58px;
        border-radius: 999px;
        font-size: 24px;
        

      }

      }

      
      @media (min-width: 1400px) and (max-width: 1550px) {
 
            .specialities-name{

              font-size: 23px;
            }
            .specialities-description{

              font-size: 16px;
              font-weight: 500;
            }


      }