.dashboard {



    background-color:  #cad4eb;
    padding: 45px;
    font-family: 'Montserrat' , sans-serif ,Arial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
   
    
  }
  

  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }


  .main-heading {
    display: flex;
    font-size: 55px;
    margin-top: 0px;
    color: #006666;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    
    
  }


  .maindash {

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color:  #fff;
  margin-top: 40px;
  border-radius: 20px;
  
 
  

  }
  
  


  .content-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
   

  
   
   
  }

  .tap-container {
    display: flex;
    flex-direction: row;
    margin-top: -20px;
    
  }




  .tapm{

    
    font-size: 18px;
    color: black;
    font-family: 'Roboto';
    margin-left: 20px;
     
 }

 
 .tapm-container {

    
  justify-content: center;
 
}

   .tap{

    font-size: 65px;
     font-family: 'Montserrat' , sans-serif ,Arial;
    font-weight: 1200px;
    margin-left: 95px;
    margin-top: -40px;
    
    
   
    

   }



   @keyframes countup {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .counting {
    animation: countup 1s ease-out;
  }
  

  
   
   .column-image {
   
   
   
   
    width: 50px; 
    height: 50px; 
    margin-bottom: 40px;
   
   
   
    
   

   }

   .dashboard-col1{
   
   
    background-color: #fff;
    width: 280px;
    height: 150px;
    color: #1171ef;
    border-radius: .7rem;
    margin-right: 40px;
    padding: 10px;
    border: none;
   
    margin-top: 50px;
   
  }
   

  .dashboard-col2 {
   
   
    
    background-color: #fff;
    height: 150px;
    width: 280px;
    color: rgb(48, 155, 48);
    border-radius: .7rem;
    margin-right: 40px;
    padding: 10px;
    border: none;
   
    margin-top: 50px;
  }

 


  .dashboard-col3 {


   
   
    background-color: #fff;
    width: 280px;
    height: 150px;
   
    color: #DC3545;
    border-radius: .7rem;
    margin-right: 40px;
    padding: 10px;
    border: none;
    margin-top: 50px;
  
  }

  .dashboard-col4 {

   
   
    background-color: #fff;
    width: 280px;
    height: 150px;
    color:  #E9D502;
    border-radius: .7rem;
   
    padding: 10px;
    border: none;
   
    margin-top: 50px;
   
  }

     
 @media (min-width: 992px) and (max-width: 1229px) {


   .tap {

     margin-left: 105px;

   }

 }


    
 @media (min-width: 1600px) and (max-width: 1900px) {


  .maindash {

    max-width: 1550px;
  }

  .dashboard-col1,
  .dashboard-col2,
  .dashboard-col3,
  .dashboard-col4 {

  
  max-width: 23%;
  margin-right: 20px;
  margin-left: 30px;
 
  }


 }
  
 @media (min-width: 1230px) and (max-width: 1444px) {


  .maindash {

    max-width: 1200px;
  }

  .dashboard-col1,
  .dashboard-col2,
  .dashboard-col3,
  .dashboard-col4 {

  
  max-width: 21%;
  margin-right: 20px;
  margin-left: 30px;
 
  }


 }

 @media (min-width: 750px) and (max-width: 1230px) {


  .maindash {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 950px;
   
   
    }

    .main-heading {

      margin-bottom: 40px;
      font-size: 50px;
      
     
    }

    
    .dashboard-col1,
    .dashboard-col2,
    .dashboard-col3,
    .dashboard-col4 {

    flex: 0 0 35%;
    max-width: 35%;
    margin-right: 40px;
    margin-left: 40px;
   
    }

 
}

@media  (min-width: 520px) and (max-width:750px) {



  .maindash {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 500px;
   
    }

    .main-heading {

      margin-bottom: 30px;
      font-size: 40px;
      
      
    }

  .dashboard-col1,
  .dashboard-col2,
  .dashboard-col3,
  .dashboard-col4 {

  flex: 0 0 40%;
  margin-right: 35px;
 

  }

  
  .tap {
  
    font-size: 65px;
    margin-left: 75px;

  }

   .tapm {

    font-size: 16px;
    font-weight: 500px;
   }


   .column-image {


    width: 40px;
    height: 50px;
   }


 
}


  @media(min-width: 350px) and (max-width: 520px) {


   
  .maindash {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 300px;
    justify-content: center;
    align-items: center ;
    margin-left: 0px;
   
    }


    .dashboard-col1,
    .dashboard-col2,
    .dashboard-col3,
    .dashboard-col4 {
  
    margin-right: 15px;
   
  
    }



    .main-heading {

      font-size: 30px;
      white-space: nowrap;
     
     
      
    }

    .tap {

      font-size: 63px;
      margin-left: 120px;

    }
  
     .tapm {

      font-size: 16px;
      font-weight: 500px;
     }

  
     .column-image {


      width: 40px;
      height: 40px;
      margin-left: 30px;
     }



  }


  
  @media (max-width: 350px) {


   
    .maindash {
  
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
       max-width: 250px;
    
     
      }
  
  
      .dashboard-col1,
      .dashboard-col2,
      .dashboard-col3,
      .dashboard-col4 {
    
      flex: 0 0 60%;
     
     
    
      }
  
  
  
      .main-heading {
      display: flex;
        font-size: 30px;
        margin-left: 0px;
        justify-content: center;
        align-items: center;
       
        
      }
  
      .tap {
  
        font-size: 60px;
        margin-left: 95px;
  
      }
    
       .tapm {
  
        font-size: 15px;
        font-weight: 500px;
       }
  
    
       .column-image {
  
  
        width: 40px;
        height: 40px;
        margin-left: 10px;
       }
  
  
  
    }
  
   
