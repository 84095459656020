
        .whit-box {
            display: flex;
            flex-direction: column;
            background-color: white;
            width: 430px;
            height: 410px;
            padding: 20px;
            text-align: center;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
                
        .otp-container {

            display: flex;
            
        }



                .otp-input {

                    
                   
                    width : 50px;
                    height: 50px;
                    margin-right: 10px;
                    margin-top: 30px;
                    padding-left: 20px;
                    border: 1px solid black;
                }



                .sendagain {

                    margin-top: 20px;
                }

                .alertotp {


                    height: 20px;
                    position: absolute;
                    top: 40%;
                    right: 1;
                    width: 250px; 


                }


                @media (max-width: 500px) {


                    .whit-box {
                        background-color: white;
                        width: 280px;
                        height: 320px;
                        padding: 20px;
                        text-align: center;
                        border-radius: 10px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    }

                    .otp-input {


                        width : 40px;
                        height: 40px;
                       
                    }

                    .resetbtn {


                        width : 200px;
                        height : 40px;
                        font-size: 17px;
                    }
    
                  
                .sendagain {

                   font-size: 14px;
                }

                 .otpsub {

                    font-size: 14px;
                 }


                    }

                    
                