.header-section {
    background-color: #f8f9fa;
    padding: 10px 0;
    font-family: Montserrat, sans-serif, Arial;
  }

  .spinner-container {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 80vh; 
  }

   .sptext {

    margin-top: 20px;
    
   }
  
  .btn {

    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }


  .h-logo {
    width: 75px;
    height: 75px;
  }


  .logo-text {


    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .logo-container {
    display: flex;
    align-items: center;
  }

  .logo-line1 {
    font-size: 25px;
    font-weight: 600;
    color: #1bbaaf;
    margin-top: 12px;
  }

  .logo-line2 {
    font-size: 15px;
    color: #807E7E;
    margin-top: -7px;
    font-weight: 550;

  }

  .navothers.nav-link {
     

  
    font-size: 17px;
    font-weight: 700px;
  }





  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: url("https://img.freepik.com/free-icon/three-horizontal-rods_318-35214.jpg?size=626&ext=jpg&ga=GA1.2.1053551889.1668418158&semt=ais");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }




  .nav-links-container .nav {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .navothers {
    color: #333;
    margin-right: 40px;
    padding: 10px 15px;
    text-decoration: none;
    transition: color 0.3s ease;
    white-space: nowrap;
   

  }


   .fobtn {
    display: flex;
    flex-direction: row;
    margin-left: 250px;
   
   }

   
  
  .iphd.input-group {

    width: 150px;
    height: auto;
   
  }

  .form.me-2-custom-input:focus {
    outline: none;
    box-shadow: none; 
    border: 1px solid #1bbaaf;
   
}


  .iphd {


    margin-right: 60px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    


  }

  .form.me-2-custom-input {

    background-color: #fff;
    


  }
     
  .header.btn-appointment.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1bbaaf;
    --bs-btn-border-color: #1bbaaf;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #008a80;
    --bs-btn-hover-border-color: #008a80;
    --bs-btn-focus-shadow-rgb: none;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #1bbaaf;
    --bs-btn-active-border-color: #1bbaaf;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: none;
    --bs-btn-disabled-border-color: none;


  }

  
  .header.btn-appointment {
    
  
    height: 40px;
    width: 180px;
    margin-top: 8px;
    background-color: #1bbaaf;
    white-space: nowrap;
    border-radius: 50px;
    margin-right: 35px;
    border: #1bbaaf;
   box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    visibility: visible;
     opacity: 1;


  }


  
  @media (max-width: 400px) {


    .navbar-toggler-icon {

      font-size: 15px;
      
    }

    .navothers.nav-link {
      font-size: 13px;
      font-weight: 500;

    }


    .h-logo {

      width: 50px;
      height: 50px;

    }

    .logo-line1 {
    font-size: 20px;
    margin-top: 8px;
  }

  .logo-line2 {
    font-size: 10px;
      margin-top: -5px;
   
  }

  .iphd.input-group {

    width: 180px;
    height: 20px;
    margin-bottom: 20px;
   
   
  }

  }


   
  @media (max-width: 1299px) {

    
    .navothers {
     
      margin-right: 0px;
      margin-left: 0px;
    


    }

    .iphd.input-group {

      width: 170px;
    }

  }

  .navothers.nav-link {

    margin-left: 20px;
    font-size: 18px;
    font-weight: 700px;
    margin-top: 10px;


  }


 

  @media (min-width: 1300px) and (max-width: 1400px){


    .navothers {
     
      margin-right: 13px;
  
    
    }

     .navothers.nav-link{

      font-size: 16px;
     }
    

    .iphd.input-group {

      width: 140px;
      height: 20px;
      margin-bottom: 20px;
     
    }

     
  .header.btn-appointment {
    
    margin-left: 10px;
    height: 35px;
    width: 150px;
    margin-right: 20px;
    font-size: 13px;
  

  }

  .fobtn {

    margin-left: 180px;
  }


  }

    
  @media (min-width: 1400px) and (max-width: 1500px){

    .fobtn {
     
      margin-left: 160px;
     
     }

  }

  @media (min-width: 1500px) and (max-width: 1600px){

    .fobtn {
     
      margin-left: 250px;
     
     }

  }


  @media (min-width: 1400px) and (max-width: 1600px){


    .navothers {
     
      margin-right: 25px;
  
    
    }

     .navothers.nav-link{

      font-size: 16px;
      margin-left: 30px;
     }
    

    .iphd.input-group {

      width: 140px;
      height: 20px;
      margin-bottom: 20px;
     
    }
     
  .header.btn-appointment {
    
    margin-left: 10px;
    height: 35px;
    width: 150px;
    margin-right: 20px;
    font-size: 13px;
  

  }


  }




  @media (min-width: 1600px) and (max-width: 1850px){


    .navothers.nav-link {
     
      
      margin-left: 30px;
      font-size: 17px;

    }

    .iphd.input-group {

      width: 140px;
      height: 20px;
      margin-bottom: 20px;
    
     
    }

    .fobtn {
     
      margin-left: 200px;
     
     }

  }

