
.ft.text-white {

    background-color:  #137c8b;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    
    
   
  } 
  
  
  
    .fter-text {
    
   
    color: white;
    
   
    font-family: 'Montserrat', sans-serif, Arial;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 400;
    line-height: 1.2;
    font-size: 18px;
   

  }
  
  
   .fter-link {
  
     color: white;
     text-decoration: none;
      
  
   }
  

   .fter-link:hover{
  
    color: black;
    text-decoration: none;
     
 
  }
 
   .fter-link:visited {
    color: white;
  }
  
  
 
  @media (max-width: 500px) {

    .fter-text {


    font-size : 11px;
    text-align: center;
    white-space: normal;


  }

  .fter-link {
    display: inline-block; 
   
  }

}


@media  (min-width:500px) and (max-width: 550px) {

  .fter-text {

    
  font-size : 12px;


}
}


@media  (min-width:550px) and (max-width: 700px) {

  .fter-text {

    
  font-size : 13px;


}
}




@media  (min-width:700px) and (max-width: 1000px) {

  .fter-text {

    
  font-size : 15px;
  
 


}
}