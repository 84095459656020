




.tlist {
      
    display: flex;
    flex-direction: column;
    
    
  }

   
  .spiner-container {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 20vh; 
    margin-top: 40px;
  }

   .stext {

    margin-top: 20px;
    
   }



  .ttableheading {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  margin-top: 30px;
  
  }

  .tmaintable  {

    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
.patient-container {
    
  
      display: flex;
      flex-direction: column; 
      align-items: center;
      width: 1250px;
      margin-top: 50px;
      color: black;
      margin-bottom: 15px;
      padding: 20px;
    
    }
    


    .tpatient-row {
      display: flex;
      justify-content: space-between;
      color: black;
      margin-bottom: 20px;
      width: 1100px;
      border-bottom: 1px solid #1bbaaf;
      align-items: center;
      
    
      
      
    }
  
  
    .tpatient-row1{
      display: flex;
      width: 1100px;
      justify-content: space-between;    
      margin-bottom: 10px;
      border-radius: .5rem;
      height: 50px;
    
    }


    .table>:not(caption)>*>* {

    
    border-bottom-width: 0;

    }

   


    
   .tpatient-row1:nth-child(even)  {
      background-color: #e7e7e7;
    }
  
    .tpatient-row1:nth-child(even) .patient-namerev{

      background-color: #e7e7e7;
    }
    
    .tpatient-row1:nth-child(even) .actionbtn {

      background-color: #e7e7e7;

    }

  
    

    .tpatient-namerev {

      font-size: 20px;
      font-weight: 400px;
    
      
    }

  
    
    .tpatient-name{
    
      display: flex;
      font-size: 25px;
     
      
    
    }
  
    .taction-container {
      margin-right: 55px;
    
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    
    }


    .tactionbtn {
  
      margin-right: 10px;
    
    }
    
  
  .tcall-btn,
  .tmsg-btn {
    margin-right: 20px;
    width: 80px;
    height: 30px;
    border-radius: 15px; 
    background-color: #1bbaaf;
    border: none;
    color: #fff; 
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .tcall-btn:hover,
  .tmsg-btn:hover {

    background-color: #008a80;
  }
  
  .iicon {


    justify-content: center;
    align-items: center;
  }



   .popiicon {

    margin-right: 10px;
   
   }

  .poplink {


    text-decoration: none;
    color: black;
  }

  .poplink:hover {

    color: rgb(93, 93, 93);
  }

  

    .table {
      border-collapse: collapse;
    
    }
    
    .tpatient-row1:hover {
      cursor: pointer;
    }
    
    
    
    .tpatient-row td {
      padding: 8px;
    
    }
  
    .tpatient-row1 td {
      padding: 8px;

    }


    .tpopup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5); 
      z-index: 999; 
    }
    
    .tpopup {
      width:  600px;
      height: 490px;
      background-color: #fff;
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    .tpophead  {
      margin-bottom: 30px;
      text-align: center;
      font-size: 40px; 
      color: #1bbaaf;
      margin-top: 20px;
     
    }
    
    


    .tfo {

      display: flex;
      flex-direction: row;
      font-size: 20px;
      margin-top: 15px;
      
      
    }


    .tfp {

      white-space: nowrap;
      width: 20px;
      margin-left: 20px;

    }

    .tfnme {

      white-space: wrap;
      width: 150px;
      margin-left: 20px;
  
     }
     


   .tfpp {

      margin-left: 15px;
      width: 350px;
     

   }

    .clpop {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    
    .cpop {
      
      width: 80px;
      height: 30px;
      margin-bottom: 20px;
      border-radius: 999px;
      background-color: #1bbaaf;
      border: #1bbaaf;
      color: #fff;
      
     
    }







    

    .tpagination-container {


      display: flex;
      margin-bottom: 80px;
      justify-content: center;
      align-items: center;
      
      
    }
  
    .pbtn{

      border-radius: 999px;
      background-color: #1bbaaf;
      border: #1bbaaf;
      color: #fff;
      margin-right: 10px;
      width: 80px;
      height: 30px;
      font-size: 15px;
    }

    .nbtn{

      border-radius: 999px;
      background-color: #1bbaaf;
      border: #1bbaaf;
      color: #fff;
      margin-left: 10px;
      width: 80px;
      height: 30px;
      font-size: 15px;
    }

    .pagination > li {
      display: inline-block;
      margin-right: 5px;
    }
    
    .pagination > li > a {
      color: #1bbaaf;
      padding: 6px 12px;
      text-decoration: none;
      border: 1px solid #1bbaaf;
      border-radius: 5px;
    }
    
    .pagination > li > a:hover {
      background-color: #1bbaaf;
      color: #fff;
    }
    
    .pagination > .active > a {
      background-color: #1bbaaf;
      color: #fff;
    }
    


    @media (max-width: 400px) {


      .tlist {
      
       
        margin-left: 10px;
      
        
        
      }

      .ttableheading{

        font-size: 40px;
      }

        
      .tpatient-container {
    
        width: 320px;
      
      }
      
      .tpatient-row {
      
      
        width: 260px;
       
      
        
      }
    
    
      .tpatient-row1{
        
        width: 260px;
      
      }
    
    
      .tpatient-name
      {
       
        font-size: 19px;
        
        
      
      }

      .tpatient-namerev {

        font-size: 13px;
        font-weight: 500;
       

      }
    
      .taction-container {
       
        font-size: 19px;

      
      }


    .tcall-btn,
    .tmsg-btn {
      
      width: 50px;
      height: 25px;
      
    
    }
  
      .tpopup {
       
        width: 250px;
        height: 460px;
      }
      
      

      .tpophead {

        justify-content: center;
        font-size: 25px;
        font-weight: 500px;
        margin-top: 20px;

      }

      .tfo {

        font-size: 11px;
        font-weight: 500;

      }
       
      .tfp {

        width: 2px;
        margin-left: 0px;
  
      }
  
     .tfnme {
  
      white-space: wrap;
       width: 70px;
    
     }
  
  
     .tfpp {
  
    
     width: 140px;
       
  
     }


      .tpagination-container {

      font-size: 15px;
      margin-bottom: 90px;
     
    
        
      }
  

    }


    @media (min-width: 300px) and  (max-width: 500px) {

      .tlist {
      
        margin-left: 10px;
      
        
        
      }

      .tpatient-container {
    
        width: 350px;
        margin-top: 50px;
        margin-bottom: 15px;
        padding: 20px;
      
      }
      
      .tpatient-row {
      
        margin-bottom: 20px;
        width: 300px;
        border-bottom: 1px solid #1bbaaf;
      
        
      }
    
    
      .tpatient-row1{
        
        width: 300px;
          
        margin-bottom: 10px;
        border-radius: .5rem;
        height: 40px;
      
      }
    
    
      .tpatient-name
      {
       
        font-size: 17px;
        
        
      
      }

      .tpatient-namerev {

        font-size: 16px;
       
      
        
      }

    
      
      
    
      .taction-container {
        margin-left: 50px;
        font-size: 17px;
      
      }


      .tactionbtn {
    
        margin-right: 10px;
      
      }
      
      
    
    
    .tcall-btn,
    .tmsg-btn {
      margin-right: 20px;
      width: 55px;
      height: 25px;
      border-radius: 999px; 
    
    }
  
     
      
      .tpopup {
       
        width: 300px;
        height: 470px;
      }
      
      

      .tpophead {

        justify-content: center;
        font-size: 25px;
        font-weight: 500px;
        margin-top: 10px;


      }

      .tfo {

        font-size: 15px;
        font-weight: 500;


      }

      .tfp {

        margin-left: 0px;
      }
      


      .tpagination-container {

        margin-bottom: 90px;
     
    
        
      }
  

    }


    
    @media   (min-width: 500px ) and (max-width: 900px) {

      .tlist {
      
       
        margin-left: 10px;
      
        
        
      }

      .tpatient-container {
    
        width: 530px;
        margin-top: 50px;
        margin-bottom: 15px;
        padding: 20px;
      
      }
      
      .tpatient-row {
      
        margin-bottom: 20px;
        width: 480px;
        border-bottom: 1px solid #1bbaaf;
      
        
      }
    
    
      .tpatient-row1{
        
        width: 480px;
        height: 40px;
      
      }
    
    
      .tpatient-name
      {
       
        font-size: 17px;
        
        
      
      }

      .tpatient-namerev {

        font-size: 16px;
       
      
        
      }
    
      .taction-container {
        margin-left: 50px;
        font-size: 17px;
      
      }


      .tactionbtn {
    
        margin-right: 10px;
      
      }
      
      
    
    
    .tcall-btn,
    .tmsg-btn {
      margin-right: 20px;
      width: 55px;
      height: 25px;
      border-radius: 999px; 
    
    }      
      
    .tpopup {
       
      width: 480px;
      height: 500px;
    }
    
    

    .tpophead {

      
      font-size: 30px;
      font-weight: 700px;
      margin-top: 10px;

    }

    .tfo {

      font-size: 17px;

    }
    


      .tpagination-container {

        margin-bottom: 80px;
     
    
        
      }
  

    }


    @media   (min-width: 900px ) and (max-width: 1300px) {


      .tlist {
      
        margin-left: 10px;
      
        
        
      }


      .tpatient-container {
    
        width: 930px;
        margin-top: 50px;
        margin-bottom: 15px;
        padding: 20px;
      
      }
      
      .tpatient-row {
      
        margin-bottom: 20px;
        width: 880px;
        border-bottom: 1px solid #1bbaaf;
      
        
      }
    
    
      .tpatient-row1{
        
        width: 880px;
        height: 40px;
      
      }
    
    
      .tpatient-name
      {
       
        font-size: 20px;
        
        
      
      }

      .tpatient-namerev {

        font-size: 19px;
       
      
        
      }
    
      .taction-container {
        
        font-size: 20px;
        margin-right: 45px;
      
      }


      .tactionbtn {
    
        margin-right: 10px;
      
      }
      
      
    
    
    .tcall-btn,
    .tmsg-btn {
      margin-right: 20px;
      width: 55px;
      height: 25px;
      border-radius: 999px; 
    
    }
      
    .tpopup {
       
      width: 530px;
      height: 500px;
    }
    
    

    .tpophead {

     
      font-size: 35px;
      font-weight: 800px;
      margin-top: 10px;

    }

    .tfo {

      font-size: 18px;

    }
    

      .tpagination-container {

        margin-bottom: 80px;
     
    
        
      }
  

    }