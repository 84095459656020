.general-section {
  padding: 40px 0;
  margin-bottom: 30px;
  display: flex;
  font-family:  'Montserrat',sans-serif,Arial;
}

.lines-container {
  text-align: left;
}

.lines {
  margin-top: 20px;
  margin-left: 15px;
  color: #545454;
  font-size: 22px;
  
  
}

.text-center {
  text-align: center;
  
}

     



.gen.font-weight-bold {
  font-weight: 800;
  margin-bottom: 50px; 
  font-weight: 800;
  font-size: 62px;
}

.text-center.img-fluid {
  max-width: 751px;
  height: 515px;
  margin-top: 50px;
}

.lines ul {
  list-style-type: none;
  padding-left: 0;
  
}



.lines ul li {
  
  align-items: center;
  margin-top: 18px;
  margin-bottom: 10px; 
}

.lines ul li:before {
  content: "\2713";
  display: inline-block;
  width: 1em;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #fffcfc;
  color: #161616;
  text-align: center;
  vertical-align: middle;
  line-height: 1em;
}

    .notconf.btn.btn-primary {
      --bs-btn-color: #fff;
      --bs-btn-bg: #1bbaaf;
      --bs-btn-border-color: #1bbaaf;
      --bs-btn-hover-color: #fff;
      --bs-btn-hover-bg: #008a80;
      --bs-btn-hover-border-color: #008a80;
      --bs-btn-focus-shadow-rgb: none;
      --bs-btn-active-color: #fff;
      --bs-btn-active-bg: #1bbaaf;
      --bs-btn-active-border-color: #1bbaaf;
      --bs-btn-active-shadow: none;
      --bs-btn-disabled-color: #fff;
      --bs-btn-disabled-bg: none;
      --bs-btn-disabled-border-color: none;


    }

.notconf.btn  {
  font-weight: bold;
  margin-top: 20px;
  display: block;
  background-color: #1bbaaf;
  border-color: #1bbaaf;
  border-radius: 999px;
  box-shadow: none;
  margin-left: 0px;
  
}



@media (max-width: 767px) {


  

  .gen.font-weight-bold {
     
    font-size: 35px;
  }


  .lines {
   
     font-size: 18px;
   
  }

  
   .notconf  {

   margin-left: 100px;

   }
}