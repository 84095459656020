
.review-section {
    padding: 20px;
    background-color: #F9F8F8;
    font-family: 'Montserrat', sans-serif, Arial;
  }
  


.review-item:nth-child(3n) {
margin-right: 0;
}


  
.revcol{

text-align: center;




}
  
  .mainrev {
    font-weight: 800;
    font-size: 62px;
}

  .rev {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.revauthor{


    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 20px;
    
}

.text-muted {

    font-size: 25px;
    text-align: center;
  
}


 .quote-icon {

  margin-right: 15px;
  
 }


.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  color: black;
  font-size: 34px;
}


.custom-arrow.prev-arrow {

  left: 60px;
  z-index: 11;

}

.custom-arrow.next-arrow {
  right: 60px;
}

  
  

  .slick-dots {

    margin-top: 20px;
    position: relative;
    bottom: 0px;
    margin-bottom: 15px;

  }
  
  @media (min-width: 1400px)  and (max-width: 1801px){


    
    .mainrev {
      font-weight: 800;
      font-size: 55px;
  }
  
    .rev {
    
      font-size: 24px;
    
  }
  
  .revauthor{
  
  
      font-size: 22px;
      margin-top: 30px;
      margin-bottom: 20px;
      
  }
  
  .text-muted {
  
      font-size: 20px;
      text-align: center;
    
  }
  
      
   .quote-icon {
  
    margin-right: 15px;
    
   }
  


  }



  

  @media (max-width: 1200px) {

    
    .revcol {


      margin-left: 0px;
    }

    .custom-arrow.prev-arrow {
      left: -13px;
     
    }
    
    .custom-arrow.next-arrow {
      right: -13px;
    }


  }

  @media (min-width: 700px) and (max-width: 1000px){

    .mainrev {
      font-weight: 800;
      font-size: 48px;
  }

    .rev {
      font-weight: 500;
      font-size: 25px;
      
  }

  .revauthor{


      font-size: 22px;
      margin-top: 30px;
      
  }

  .text-muted {

      font-size: 18px;
      margin-left: 10px;
      margin-right: 10px;

  }

  .custom-arrow.prev-arrow {
    left: -10px;
   
  }
  
  .custom-arrow.next-arrow {
    right: -10px;
  }



  }

  
  @media (min-width: 451px) and (max-width: 699px) {


    .mainrev {
      font-weight: 800;
      font-size: 40px;
  }

    .rev {
      font-weight: 500;
      font-size: 17px;
     
  }

  .revauthor{


      font-size: 20px;
      margin-top: 30px;
      
  }

  .text-muted {

      font-size: 16px;
     
  }

  .custom-arrow.prev-arrow {
    left: -15px;
  }
  
  .custom-arrow.next-arrow {
    right: -15px;
  }


    
  }
 
   
  @media (max-width: 451px) {


    .quote-icon {

      margin-right: 1px;
      
     }

    .mainrev {
      font-weight: 800;
      font-size: 30px;
  }

    .rev {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: -20px;
  }

  .revauthor{


      font-size: 18px;
      margin-top: 30px;
      
  }

  .text-muted {

      font-size: 11px;
      font-weight: 600;
     
  }

  
.custom-arrow.prev-arrow {
  left: -20px;
}

.custom-arrow.next-arrow {
  right: -20px;
}

 .review-item{

  margin-top: 0px;
 }


 .custom-arrow {

  font-size: 21px;
 }
    
  }