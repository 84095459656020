    
            
            
            .carousel-item {
                background-color: white;
                height: 200px;
                margin-top: 20px;
              }
    
    
    
              .main {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: 5px;
                font-family: Montserrat, sans-serif, Arial;
              }
    
              .carohead {
                margin-top: 10px;
                color: black;
                font-size: 62px;
                font-family: Montserrat, sans-serif, Arial;
                font-weight: 800;
              }
    
              .subcarou {
                
                display: block;
                font-weight: 500;
                font-size: 25px;
                color:  #8F8F8F;
                margin-bottom: 80px;
                font-family: Montserrat, sans-serif, Arial;
              }
    
                .custom-slide {
                background-color: grey;
                color: black;
              }
    
    
           
    
              .service-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 60px;
                color: #5C5C5C;
                font-family: Montserrat, sans-serif, Arial;
                font-weight: 500;
                font-size: 24px;
                text-align: center;
              }
              
              .service-item-image {
                width: 150px;
                height: 100px;
                object-fit: contain;
                margin-bottom: 15px;
                border-radius: 99px;
                transition: transform 0.3s ease;
              }

              .servname {


                white-space: nowrap;
              }
              
              .service-item:hover .service-item-image {
                transform: scale(1.1);
              }
              
              .service-item:hover {
                color: #5C5C5C;
              }
              
    
               
              .carousel .carousel-indicators {
                background-color: white;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 2;
                  display: flex;
                  justify-content: center;
                  padding: 0;
                  margin-right: 15%;
                  margin-top: 20px;
                  margin-left: 15%;
                }
                

                .carousel-control-next{


                  margin-right: -60px;
                  margin-top: -35px;
                }


                 .carousel-control-prev {

                  margin-top: -35px;
                  margin-left: -50px;
                 }
  
    
    
              .carousel .carousel-indicators li {
                background-color: lightgrey;
              }
    
              .carousel .carousel-indicators .active {
                background-color: gray;
              }
    
    
    
    
              .carousel-control-prev-icon,
              .carousel-control-next-icon {
                filter: invert(100%) ;
               
              }
                  
    
              
              .ser-bt.btn.btn-primary {
                --bs-btn-color: #fff;
                --bs-btn-bg: #1bbaaf;
                --bs-btn-border-color: #1bbaaf;
                --bs-btn-hover-color: #fff;
                --bs-btn-hover-bg: #008a80;
                --bs-btn-hover-border-color: #008a80;
                --bs-btn-focus-shadow-rgb: none;
                --bs-btn-active-color: #fff;
                --bs-btn-active-bg: #1bbaaf;
                --bs-btn-active-border-color: #1bbaaf;
                --bs-btn-active-shadow: none;
                --bs-btn-disabled-color: #fff;
                --bs-btn-disabled-bg: none;
                --bs-btn-disabled-border-color: none;
        
        
              }
    
    
    
              .ser-bt.btn{
                        
                background-color: #1bbaaf;
                border-color: #1bbaaf;
                border-radius: 999px;
                box-shadow: none;
                -moz-box-shadow: none;
                -webkit-box-shadow: none;
              }
    
                
              .btn {

                --bs-btn-box-shadow: none;
                --bs-btn-focus-box-shadow: none;
               }
              
    
              .carousel-control-prev-icon::after
               {
    
                    display: none;
              }
              .carousel-control-next-icon::after{
    
                display: none;
                
              }

              @media (max-width: 350px) {


                .service-item-image {
                  width: 90px;
                  height: 60px;
                  margin-bottom: 15px;
                 
                }

                .service-item{

                  margin-right: 10px;
                
                }
                
                
                     
                .servname {

                   font-size: 10px;
                   white-space: wrap;
                  
                }

                .carousel-control-next{


                  margin-right: -10px;
                  margin-top: -35px;
                }


                .carousel-control-prev {

                  margin-top: -35px;
                  margin-left: 0px;
                 }


                            
              }

              @media (min-width: 351px) and (max-width: 460px) {


               
  
                
                .service-item-image {
                  width: 100px;
                  height: 80px;
                  margin-bottom: 15px;
                
                                
                }
                .service-item {

                  margin-right: 30px;
                }

                .servname {

                   font-size: 13px;
                   white-space: wrap;
                  
                }

                .carousel-control-next{


                  margin-right: 0px;
                  margin-top: -35px;
                }


                 .carousel-control-prev {

                  margin-top: -35px;
                  margin-left: 0px;
                 }

             

              }

    
    
               @media (max-width: 460px) {
    
                 
                    
    
                  .carohead {
                    display: flex;
                    font-size: 30px;
                    justify-content: center;
                  align-items: center;
                  text-align: center;
                  margin-top: -10px;
                    
                  }
    
                  .subcarou {
                    
                    font-size: 14px;
                    color:  #8F8F8F;
                  
    
                                   
                  }
    
                
  
                  
    
              }
    
    
               
    
              
              @media  (min-width: 460px) and (max-width: 768px) {
    
                
    
                  .carohead {
                    margin-top: -20px;
                    display: flex;
                    font-size: 38px;
                    justify-content: center;
                  align-items: center;
                  text-align: center;
                    
                  }
    
                  .subcarou {
                    
                    font-size: 18px;
                    color:  #8F8F8F;
                  
    
                  
                  
                  }
    
                  .service-item {
                    
                    margin-right: 20px;
                    margin-left: 20px;
               
                    
                  }

                  .service-item-image {
                    width: 100px;
                    height: 80px;
                    margin-bottom: 15px;
                    margin-right: 20px;
                   
                  
                  }

                  .servname {

                     font-size: 19px;
                    
                  }
    
                  .carousel-control-next{


                    margin-right: 0px;
                    margin-top: -35px;
                  }
  
  
                   .carousel-control-prev {
  
                    margin-top: -35px;
                    margin-left: 0px;
                   }
                  
    
              }
    
    
              @media (min-width: 768px) and (max-width: 1000px) {
    
                .main {
    
                margin-top: 5px;
    
                }
    
    
    
                .carohead {
              
                  font-size: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  
                    
                }
                .subcarou {
    
                  font-size: 45px;
                  margin-top: 15PX;
              
                
                }
    

                .service-item-image {
                  width: 120px;
                  height: 80px;
                
                }

                .servname {

                  font-size: 20px;
                }
    
              
              .service-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 25px;
                color: #5C5C5C;
               font-weight: 500;
               
                text-align: center;
              }
              
             
              
              .service-item:hover .service-item-image {
                transform: scale(1.1);
              }
              
              .service-item:hover {
                color: #5C5C5C;
              }
    
              .ser-bt.btn{
    
              width: 340px;
              height: 58px;
              border-radius: 999px;
              font-size: 24px;
             
              margin-bottom: 20px;
    
    
              }
                
              .carousel-control-next{


                margin-right: -30px;
                margin-top: -35px;
              }


               .carousel-control-prev {

                margin-top: -35px;
               }
              

          }

          @media  (min-width: 1000px) and (max-width: 1200px) {

            .main {
    
              margin-top: 5px;
  
              }
  
  
  
              .carohead {
            
                font-size: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                
                  
              }
              .subcarou {
  
                font-size: 45px;
                margin-top: 15px;
            
              
              }
  

              .service-item-image {
                width: 110px;
                height: 80px;
              
              }

              .servname {

                font-size: 18px;
              }
  
            
            .service-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-right: 45px;
              color: #5C5C5C;
             font-weight: 500;
              text-align: center;
            }
            
           
            
            .service-item:hover .service-item-image {
              transform: scale(1.1);
            }
            
            .service-item:hover {
              color: #5C5C5C;
            }
  
            .ser-bt.btn{
  
            width: 340px;
            height: 58px;
            border-radius: 999px;
            font-size: 24px;
           margin-bottom: 20px;
  
  
            }
              
            .carousel-control-next{


              margin-right: -45px;
              margin-top: -35px;
            }


             .carousel-control-prev {

              margin-top: -35px;
             }
            

        }

    
          @media  (min-width: 1200px) and (max-width: 1400px) {

            .main {
    
              margin-top: 5px;
  
              }
  
  
  
              .carohead {
            
                font-size: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                
                  
              }
              .subcarou {
  
                font-size: 45px;
                margin-top: 15px;
            
              
              }
  

              .service-item-image {
                width: 120px;
                height: 80px;
              
              }

              .servname {

                font-size: 19px;
              }
  
            
            .service-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-right: 55px;
              color: #5C5C5C;
             font-weight: 500;
              text-align: center;
            }
            
           
            
            .service-item:hover .service-item-image {
              transform: scale(1.1);
            }
            
            .service-item:hover {
              color: #5C5C5C;
            }
  
            .ser-bt.btn{
  
            width: 340px;
            height: 58px;
            border-radius: 999px;
            font-size: 24px;
           margin-bottom: 20px;
  
  
            }
              
            .carousel-control-next{


              margin-right: -45px;
              margin-top: -35px;
            }


             .carousel-control-prev {

              margin-top: -35px;
             }
            

        }



          