  .whte-box {
      background-color: white;
      width: 480px;
      height: 440px;
      padding: 20px;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .alertcsw {
  
      font-size: 13px;
      
      position: absolute;
      top: 70%;
      right: 1;
      width: 250px; 

    }

    @media (max-width : 500px) {


      .whte-box {
       
        width: 300px;
        height: 400px;
       
      }
      

      .pswhead {

        font-size: 25px;
        font-weight: 600;
      }


      .pswsubb {

        font-size: 15px;
        
      }

      .form-contr {

        width: 230px;
      }
    }