

#root {
  min-height: 100vh;
  position: relative;
}


html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  min-height: 100vh; 
 
  
}