  
.full{

  background-color: #fff;
}

  .ddiv  {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
  }
  
  
  .headicon {

    margin-right: 20px;
    color: #1bbaaf;
  }
  
  .logo-container {
    margin-right: 5px;
  }


 
  .btn-primary {
    --bs-btn-color: none; 
    --bs-btn-border-color: none; 
    --bs-btn-hover-color: none; 
    --bs-btn-hover-bg: none; 
    --bs-btn-hover-border-color: none; 
    --bs-btn-focus-shadow-rgb: none; 
    --bs-btn-active-color: none; 
    --bs-btn-active-bg: none; 
    --bs-btn-active-border-color: none; 
    --bs-btn-active-shadow: none; 
    --bs-btn-disabled-color: none; 
    --bs-btn-disabled-bg: transparent; 
    --bs-btn-disabled-border-color: none; 
    --bs-gradient: none; 
}
  
  
 
.headicon:hover {

  color: #006666;
}


  
.custom-dropdown-item:hover {

  
  color: #006666;
}
 
  
.custom-dropdown-item {

  display: flex;
  
  text-decoration: none; 
  color: #1bbaaf; 
  margin-top: 5px;
  font-size: 26px;
 
  background-color: inherit;
 
  border: none;
  
  z-index: 1; 

 }
  
 
@media (max-width:500px) {

  .headicon {

    margin-right: 10px;
  }

}
 











