.mapmain {


    display: flex;
    flex-direction: column;
    padding: 40px;
    font-family: 'Montserrat', sans-serif;


}

.mapframe {

    width: 780px;
    height: 450px;
    margin-left: 30px;

}

.mapyo {

    display: flex;
    align-items: center;
    justify-content: center;
}


.maphead {

    font-size: 50px;
    margin-bottom: 30px;
    font-weight: 700;

}




.maprow {

    
    display: flex;
    flex-direction: row;

}

.mapcol {


    margin-top: 40px;
}


.formmap {

    margin-top: 40px;
}



.underline-input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;

    margin-top: 10px;
}



.fmapform.form-control {
    width: 360px;
    margin-bottom: 2rem !important;
    height: 38px;


}


.fmapformc.form-check {
    width: 360px;
    margin-bottom: 2rem !important;
    height: 38px;
    white-space: nowrap;
    font-size: 14px;


}

.message.fmapform.form-control {

    height: 126px;
    width: 360px;

}

.fmapform.form-control:focus {


    box-shadow: none;
    border: 1px solid #1bbaaf;

}

.mapbtn.btn.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1bbaaf;
    --bs-btn-border-color: #1bbaaf;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1bbaaf;
    --bs-btn-hover-border-color: #1bbaaf;
    --bs-btn-focus-shadow-rgb: none;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #1bbaaf;
    --bs-btn-active-border-color: #1bbaaf;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #1bbaaf;
    --bs-btn-disabled-border-color: none;


}

.mapbtn.btn {

    background-color: #1bbaaf;
    border-color: #1bbaaf;
    box-shadow: none;
    font-size: 18px;
    font-weight: 500;
    width: 101px;
    border-radius: 999px;
    height: 40px;
    margin-top: 10px;
}

.eror {


    font-weight: 500;
    color: red;
    font-family: 'Roboto';
}

.success-message {

    margin-top: 20px;
    color: #1bbaaf;
    font-family: 'Roboto';


}

.er-message {

    margin-top: 20px;
    color: red;
    font-family: 'Roboto';
    

}

@media (max-width:1250px) {



    .maprow {

        flex-direction: column;
        

    }

    .mapframe{

        margin-left: 0;
    }

}


@media (max-width: 410px) {


    .eror {

        font-size: 12px;
        white-space: nowrap;
    }


    .maprow {


        display: flex;
        justify-content: center;
        align-items: center;

    }

    .mapcol {

        width: 260px;
        height: 300px;
    }


    .mapframe {


        width: 260px;
        height: 300px;
        margin-left: 0px;

    }


    .maphead {

        font-size: 24px;
        font-weight: 700;
        white-space: nowrap;

    }



    .fmapform.form-control {
        width: 260px;


    }

    .fmapformc.form-check {

        white-space: wrap;
        width: 260px;
        margin-left: 30px;


    }

    .message.fmapform.form-control {


        width: 260px;


    }

    .mapbtn.btn {



        font-size: 15px;

        width: 111px;
        font-weight: 500;
        height: 40px;
        margin-top: 20px;
    }

}


@media (min-width: 410px) and (max-width: 650px) {


        
.maprow {

   
        display: flex;
        justify-content: center;
        align-items: center;   
   
}


    .mapcol {

        width: 360px;
        height: 300px;
    }


    .mapframe {
        width: 360px;
        height: 300px;
    }

    .formmap {

        margin-top: 60px;
    }

    .maphead {

        font-size: 32px;


    }

   


}

@media (min-width: 650px) and (max-width: 1250px) {



            
.maprow {

   
    display: flex;
    justify-content: center;
    align-items: center;   

}


.mapcol {

    width: 520px;
    height: 300px;
}


.mapframe {
    width: 520px;
    height: 300px;
}




.fmapform.form-control {
       width: 520px;


}



.fmapformc.form-check {


    display: flex;
    width: 520px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
   

}


.fmapformc.form-check .form-check-input {
    float: left;
    margin-right: .7rem;
    margin-top: 0px;
}

.message.fmapform.form-control {


       width: 520px;


}

    .formmap {

        margin-top: 60px;
    }

}




@media (min-width: 1250px) and (max-width: 1400px) {


    .maprow {

        display: flex;
        flex-direction: row;
     }

    .mapframe {

        width: 530px;
        height: 400px;
    }


     

     
.fmapform.form-control {
    width: 400px;
    margin-bottom: 2rem !important;
    height: 38px;


}

.message.fmapform.form-control {

    height: 126px;
    width: 400px;

}

}