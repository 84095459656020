           
           
           
           
     .form-container h2 {
        margin-bottom: 5px;
        font-size: 40px;
        font-weight: 800;
        margin-top: 10px;
      }
 
      .sub-text {
        color: #8f8f8f;
        font-size: 20px;
        margin-bottom: 15px;
      }
 

      .topbtn.btn.btn-primary {
        --bs-btn-color: #fff;
        --bs-btn-bg: #1bbaaf;
        --bs-btn-border-color: #1bbaaf;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #1bbaaf;
        --bs-btn-hover-border-color: #1bbaaf;
        --bs-btn-focus-shadow-rgb: none;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #1bbaaf;
        --bs-btn-active-border-color: #1bbaaf;
        --bs-btn-active-shadow: none;
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #1bbaaf;
        --bs-btn-disabled-border-color: none;


      }
 
      .topbtn.btn {
 
        background-color: #1bbaaf;
        border-color: #1bbaaf;
        box-shadow: none;
        font-size: 15px;
        font-size: 20px;
        font-weight: 500;
        width: 111px;
        border-radius: 999px;
        height: 40px;
        margin-top: 10px;
      }
 
 
 
      .mb-3.form-control {
        width: 483px;
        margin-bottom: 1.3 rem !important;
        height: 48px;
 
      }

 
       input.dp {

        width: 483px;
        margin-bottom: 1rem;
        height: 48px;
        padding-left: 10px;
        border: 1px solid  #dee2e6;
       

       }

       .react-datepicker__close-icon {
        background-color: initial;
        border: 0;
        cursor: pointer;
        display: table-cell;
        height: 100%;
        outline: 0;
        padding: 0 5px 15px 0;
        position: absolute;
        right: 5px;
        top: 0px;
        vertical-align: middle;

       }

       .react-datepicker__close-icon:after {
        background-color: #1bbaaf;
        border-radius: 50%;
        color: #fff;
        content: "×";
        cursor: pointer;
        display: table-cell;
        font-size: 14px;
        height: 16px;
        line-height: 1;
        padding: 2px;
        text-align: center;
        vertical-align: middle;
        width: 16px;
       }


       .react-datepicker__navigation {
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        height: 32px;
        justify-content: center;
        overflow: hidden;
        padding: 0;
        position: absolute;
        text-align: center;
        text-indent: -999em;
        top: 7px;
        width: 32px;
        z-index: 1;
       }

      .message.mb-3.form-control {
 
        height: 126px;
        width: 483px;

      }

      .message.mb-3.form-control:focus,
      .mb-3.form-control:focus
      
       {
    
 
        box-shadow: none; 
        border: 1px solid #1bbaaf;
       

      }

     
      
     
      
      input.dp:focus {

        border: 2px solid #1bbaaf !important;   
       

      }
 
      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 20px;
        color: #333;
        cursor: pointer;
      }
 
           
           
           
           
           
           
           @media (max-width: 400px) {


                .form-container {
                    background-color: #f7f7f7;
                    width: 260px;
                    height: 742px;
                    padding: 15px;
                    border-radius: 15px;
                    overflow: visible;
                    top: 2%;
                    left: 10%;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    position: absolute;
                    z-index: 4;
                }





                .form-container h2 {
                    margin-bottom: 15px;
                    font-size: 26px;
                    font-weight: 800;
                    margin-top: 10px;
                }

                .sub-text {
                    color: #8f8f8f;
                    font-size: 12px;
                    margin-bottom: 10px;
                }


                .topbtn.btn {

                    background-color: #1bbaaf;
                    font-size: 15px;
                    font-weight: 700;
                    width: 101px;
                    border-radius: 999px;
                    border-color: #1bbaaf;
                    height: 40px;
                    margin-top: 15px;
                }


                .react-datepicker__close-icon {
                   
                    padding: 0 5px 15px 0;
                   
                   }

                .mb-3.form-control {
                    width: 160px;
                    margin-bottom: 1rem !important;
                    height: 38px;

                }

                .mb-3.form-check {
                    width: 160px;
                    margin-bottom: 1.5rem !important;
                    font-size: 12px;
                    height: 38px;

                }


                input.dp {

                    width: 160px;
                    margin-bottom: 1rem;
                    height: 38px;
                   
                   }

                .message.mb-3.form-control {

                    height: 126px;
                    width: 160px;


                }

                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: none;
                    border: none;
                    font-size: 20px;
                    color: #333;
                    cursor: pointer;
                }

            }

            @media (min-width: 400px) and (max-width: 431px) {


         
                .form-container {
                    background-color: #f7f7f7;
                    width: 395px;
                    height: 842px;
                    padding: 20px;
                    border-radius: 15px;
                    overflow: visible;
                    margin-top: 250px;
                    margin-bottom: -100px;
                    position: absolute;
                    top: -2.5%;
                    left: 2%;
                    z-index: 4;
                }





                .form-container h2 {
                    margin-bottom: 15px;
                    font-size: 40px;
                    font-weight: 800;
                    margin-top: 10px;
                }

                .sub-text {
                    color: #8f8f8f;
                    font-size: 13x;
                    margin-bottom: 10px;
                }


                .topbtn.btn {

                    background-color:#1bbaaf;
                    border-color: #1bbaaf;
                    font-size: 15px;
                    font-weight: 700;
                    width: 101px;
                    border-radius: 999px;
                    height: 40px;
                    margin-top: 15px;
                }


                .react-datepicker__close-icon {
                   
                    padding: 0 5px 29px 0;
                   
                   }

                .mb-3.form-control {
                    width: 320px;
                    margin-bottom: 2rem !important;
                    height: 38px;

                }

                .mb-3.form-check {
                    width: 320px;
                    margin-bottom: 1rem !important;
                    font-size: 13px;
                    height: 38px;

                }


                
                input.dp {

                    width: 320px;
                    margin-bottom: 2rem !important;
                    height: 38px;
                   
                   }

                .message.mb-3.form-control {

                    height: 126px;
                    width: 320px;


                }

                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: none;
                    border: none;
                    font-size: 30px;
                    color: #333;
                    cursor: pointer;
                }






            }



            


            @media (min-width: 431px) and (max-width: 767px) {



                .form-container {
                    background-color: #f7f7f7;
                    width: 360px;
                    height: 842px;
                    padding: 20px;
                    border-radius: 15px;
                    overflow: visible;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    position: absolute;
                    top: 2%;
                    left: 12%;
                    z-index: 4;
                }





                .form-container h2 {
                    margin-bottom: 15px;
                    font-size: 40px;
                    font-weight: 800;
                    margin-top: 10px;
                }

                .sub-text {
                    color: #8f8f8f;
                    font-size: 13x;
                    margin-bottom: 10px;
                }


                .topbtn.btn {

                    background-color:#1bbaaf;
                    border-color: #1bbaaf;
                    font-size: 15px;
                    font-weight: 700;
                    width: 101px;
                    border-radius: 999px;
                    height: 40px;
                    margin-top: 15px;
                }


                .react-datepicker__close-icon {
                   
                    padding: 0 5px 29px 0;
                   
                   }

                .mb-3.form-control {
                    width: 320px;
                    margin-bottom: 2rem !important;
                    height: 38px;

                }

                .mb-3.form-check {
                    width: 320px;
                    margin-bottom: 1rem !important;
                    font-size: 13px;
                    height: 38px;

                }

                
                input.dp {

                    width: 320px;
                    margin-bottom: 2rem !important;
                    height: 38px;
                   
                   }

                .message.mb-3.form-control {

                    height: 126px;
                    width: 320px;


                }

                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: none;
                    border: none;
                    font-size: 20px;
                    color: #333;
                    cursor: pointer;
                }






            }

            @media (min-width: 768px) and (max-width: 991px) {

                .form-container {
                    background-color: #f7f7f7;
                    width: 475px;
                    height: 772px;
                    padding: 20px;
                    border-radius: 15px;
                    overflow: visible;
                    top: 2%;
                    left: 25%;
                    position: absolute;
                    z-index: 4;
                }





                .form-container h2 {
                    margin-bottom: 15px;
                    font-size: 35px;
                    font-weight: 800;
                    margin-top: 10px;
                }

                .sub-text {
                    color: #8f8f8f;
                    font-size: 12px;
                    margin-bottom: 15px;
                }


                .topbtn.btn {

                    background-color:#1bbaaf;
                    border-color: #1bbaaf;
                    font-size: 15px;
                    font-weight: 700;
                    width: 101px;
                    border-radius: 999px;
                    height: 40px;
                    margin-top: 15px;
                }


                .react-datepicker__close-icon {
                   
                    padding: 0 5px 29px 0;
                   
                   }

                .mb-3.form-control {
                    width: 420px;
                    margin-bottom: 2rem !important;
                    height: 38px;

                }

                .mb-3.form-check {
                    width: 420px;
                    margin-bottom: 1rem !important;
                    font-size: 15px;
                    height: 38px;

                }
                 
                input.dp {

                    width: 420px;
                    margin-bottom: 2rem !important;
                    height: 38px;
                   
                   }


                .message.mb-3.form-control {

                    height: 126px;
                    width: 420px;


                }

                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: none;
                    border: none;
                    font-size: 20px;
                    color: #333;
                    cursor: pointer;
                }






            }


            @media (min-width: 990px) and (max-width: 1500px) {


            
                .form-container {
                    background-color: #f7f7f7;
                    width: 473px;
                    height: 792px;
                    padding: 20px;
                    border-radius: 15px;
                    overflow: visible;
                    top: 2%;
                    left: 50%;

                    position: absolute;
                    z-index: 4;
                }





                .form-container h2 {
                    margin-bottom: 15px;
                    font-size: 40px;
                    font-weight: 800;
                    margin-top: 10px;
                }

                .sub-text {
                    color: #8f8f8f;
                    font-size: 13x;
                    margin-bottom: 10px;
                }


                .topbtn.btn{

                    background-color: #1bbaaf;
                    font-size: 15px;
                    font-weight: 700;
                    width: 111px;
                    border-radius: 999px;
                    height: 48px;
                    margin-top: 15px;
                    border-color: #1bbaaf;
                }


                .react-datepicker__close-icon {
                   
                    padding: 0 5px 29px 0;
                   
                   }

                .mb-3.form-control {
                    width: 433px;
                    margin-bottom: 2rem !important;
                    height: 38px;

                }


                .mb-3.form-check {
                    width: 430px;
                    margin-bottom: 1rem !important;
                    font-size: 15px;
                    height: 38px;

                }

                input.dp {

                    width: 433px;
                    margin-bottom: 2rem !important;
                    height: 38px;
                   
                   }



                .message.mb-3.form-control {

                    height: 126px;
                    width: 433px;


                }

                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: none;
                    border: none;
                    font-size: 20px;
                    color: #333;
                    cursor: pointer;
                }


                }



