
  .f.text-white {
      background-color:  #137c8b;
      display: flex;
      justify-content: center;
      align-items: center;
      
    
    
    } 
    
    
    .footer-text {
    
      flex-direction: row;
      margin-top: 15px;
      font-family: Montserrat, sans-serif, Arial;
      text-decoration: none;
      white-space: nowrap;
      font-weight: 400;
      line-height: 1.2;
      font-size: 17px;
      
    }
    
    
    .footer-link {
    
      color: white;
      text-decoration: none;
    
    }

    .footer-link:hover {
    
      color: black;
  
    }
    
    .footer-link:visited {
      color: white;
    }
    
    
    
    
    .button-container {
      display: flex;
      justify-content: center;
      gap: 20px;
      
    }
    
    .f.btn {
     
      align-items: center;
      width: 147px;
      height: 48px;
    }
    
    .footer.btn {
          
    
      align-items: center;
      width: 147px;
      height: 48px;
    
    }
    
    .icon-container,
    .foicon-container {
      margin-right: 8px;
    }
    
    .foicon,
    .footericon {
      width: 23px;
      height: 28px;
    }
    
    .fotext-container,
    .footertext-container {
      display: flex;
      flex-direction: column;
    }
    
    .app-store-text {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 5px;
    
    }
    
    @media (max-width: 420px) {
      
      
      .footer-text {
    
      text-align: center;
      margin-bottom: 10px;
      margin-left: auto;
      font-size: 10px;
      flex-direction: column;
      white-space: normal;
    
    
    }
    }
    @media (min-width: 420px) and (max-width: 540px) {
    
    
      .f.text-white {
       
       
        display: flex;
        justify-content: center;
          align-items: center;
        
        } 
  
  
        .footer-text {
        
           
          
          font-size: 10px;
          margin-left: 0px;
      
        }
    
    }
    
    
    @media (min-width: 540px) and (max-width: 767px) {
    
    
      .f.text-white {
       
       
        display: flex;
        justify-content: center;
          align-items: center;
        
        } 
  
  
        .footer-text {
        
           
          
          font-size: 12px;
          margin-left: 0px;
      
        }
    
    }
    
    
    
    
    @media (min-width: 767px) and (max-width: 1199px) {
    


      .f.text-white {
       
       
      display: flex;
      justify-content: center;
        align-items: center;
      
      } 


      .footer-text {
      
         
        
        font-size: 15px;
        margin-left: 0px;
    
      }
    
    
    }
          
    
    @media (min-width: 1199px)  and (max-width: 1420px) {
    
        .f.text-white {
       
       
      display: flex;
      justify-content: center;
      align-items: center;
      
      
      } 


      .footer-text {
      
         
        
        font-size: 18px;
       
    
      }
    
    }
    
    