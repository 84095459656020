



.hero-section {

  overflow: visible;
  font-family: 'Montserrat',sans-serif;
}


.hero-section.show {
  height: 60vh;
}

.hero-image {

 
  background-size: cover;
  background-position: center;
  height: 100vh;
}


.herosectionrow {

  display: flex;
  
 
}



.herosection-text-container {

  display: flex;
  flex-direction: column;


}


.hsmainhd {

  color: white;
  text-shadow:  1px 1px #1bbaaf;
  font-weight: 800;
  line-height: 1.2;
  font-size: 60px;
  height: 156px;  
  width: 578px;
  margin-top: 20px;
 
  

}


.hsp {

  color: #FFFFFF;
  font-weight: 400;
  font-size: 28px;
  margin-top: 5px;
}



.hssp-t {
 
  color: #fff;
  width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}



.hssp.btn.btn-primary{

  margin-top: 2px;
  background-color: #1bbaaf;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  text-align: center;
  font-size: 20px;
  white-space: nowrap;
  width: 270px;
  height: 45px;
  margin-bottom: 20px;
  border-radius: 99px;
  border-color: #1bbaaf;

}




.hssp.btn.btn-primary,
.abtbtn.btn.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1bbaaf;
  --bs-btn-border-color: #1bbaaf;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg:  #008a80;
  --bs-btn-hover-border-color: #008a80;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1bbaaf;
  --bs-btn-active-border-color: #1bbaaf;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: none;
  --bs-btn-disabled-border-color: none;


}


.abtbtn.btn.btn-primary{

 
  margin-top: 10px;
  background-color: #1bbaaf;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 19px;
  white-space: nowrap;
  width: 270px;
  height: 45px;
  margin-bottom: 20px;
  border-radius: 999px;
  border-color: #1bbaaf;


}

.abtbtn.btn.btn-primary:hover,
.hssp.btn.btn-primary:hover {

  background-color: #008a80;
  border-color: #008a80;
}



.cont-section {
  background-color: #FFFFFF;
  padding: 30px 0;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;


}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}


.form-container {
  background-color: #f7f7f7;
  width: 523px;
  height: 742px;
  padding: 20px;
  border-radius: 15px;
  overflow: visible;
  margin-top: 50px;
  margin-bottom: -100px;
  position: absolute;
  left: 60%;
  z-index: 4;
  
}



.con-link {
  color: #fff;
  text-decoration: none;
  
 
}


.cont-link {
  color: #8f8f8f;
  text-decoration: none;
 
}


.cont {
  display: flex;
  align-items: center;
  line-height: 0.5;
  position: relative;
}



.maincont {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: -10px;
  font-size: 21px;
  font-weight: 600;


}

.conticon {

  vertical-align: initial;

}

.maincont2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 21px;
  margin-left: 20px;

  font-weight: 600;
}



.maincont3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 100px;
  font-size: 21px;
  font-weight: 600;


}



.maincont4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 80px;
  font-size: 21px;
  font-weight: 600;


}

.subcont {

  font-size: 20px;
  font-weight: 600;

  color: #8f8f8f;
  display: inline-block;
  white-space: nowrap;
}



.subcont2 {

  font-size: 20px;
  font-weight: 600;

  color: #8f8f8f;
  display: inline-block;
  white-space: nowrap;
}


.subcont3 {

  font-size: 20px;
  font-weight: 600;
  color: #8f8f8f;
  display: inline-block;
  white-space: nowrap;
}

.subcont4 {

  font-size: 20px;
  font-weight: 600;
  color: #8f8f8f;
  display: inline-block;
  white-space: nowrap;
}


.form-content {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 15px;
}





@media (max-width: 400px) {

  
.herosection-text-container {

  justify-content: center;
  align-items: center;


}

  .hsmainhd {

    font-size: 30px;
    margin-top: 60px;
    display: flex;
    justify-content: center;
   

  


  }

  .hsp {

    font-size: 15px;
    margin-top: -80px;
   


  }

  .abtbtn.btn.btn-primary {

   
    width: 185px;
    height: 32px;
    border-radius: 999px;
    font-size: 13px;
    margin-bottom: 10px;
    margin-top: 2px;

  }

 

  .hssp.btn.btn-primary {


    width: 185px;
    height: 32px;
    font-size: 15px;
   
   
   
  }


  .hssp-t {

    width: 185px;
  }
  

  


  .cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

  }

  .maincont,
  .maincont3,
  .maincont4,
  .maincont2 {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }

  .maincont3,
  .maincont4 {

    margin-left: 0;
  }

  .subcont,
  .subcont2,
  .subcont3,
  .subcont4 {
    margin-top: 10px;
    font-size: 13px;
  }




}



@media (min-width: 401px) and (max-width: 767px) {



    
  .herosection-text-container {
  
    justify-content: center;
    align-items: center;
  
  
  }
  
    .hsmainhd {
  
      font-size: 35px;
      display: flex;
      justify-content: center;
      margin-bottom: -40px;
      text-shadow: 1.3px 1.3px #1bbaaf;
  
    }
 

  .hsp {

    font-size: 18px;
    margin-top: -12px;

  }

  .hssp-t {

    width: 223px;
     
  }
 
  .abtbtn.btn.btn-primary{

   
    width: 223px;
    height: 40px;
    border-radius: 999px;
    font-size: 16px;
    margin-bottom: 10px;

  } 


  
  .hssp.btn.btn-primary {

    width: 223px;
    height: 40px;
    font-size: 17px;
    margin-top: -5px;
  }

 
  
  .cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

  }

  .maincont,
  .maincont2,
  .maincont3,
  .maincont4 {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  .maincont3,
  .maincont4 {

    margin-left: 0;
  }



  .subcont,
  .subcont2,
  .subcont3,
  .subcont4 {
    margin-top: 10px;
    font-size: 18px;
  }
}


@media (min-width: 767px) and (max-width: 991px) {



  .hsmainhd {

    font-size: 39px;
    margin-top: 10px;
    margin-bottom: 0;

  }

  .hsp {

    margin-top: -55px;
    font-size: 19px;
  }

  .hssp-t {

    width: 288px;
  
  }


  .abtbtn.btn.btn-primary {


    width: 278px;
    height: 45px;
    font-size: 20px;
    margin-bottom: 10px;

  }

  .hssp.btn.btn-primary {

    width: 278px;
    height: 45px;
    margin-top: -10px;

  }

  .c-section {

    display: flex;
  }


  .cont {
    display: flex;
   

    text-align: center;
    margin-top: 25px;

  }



  .maincont,
  .maincont2,
  .maincont3,
  .maincont4 {
    text-align: center;

    font-size: 22px;
  }


  
  .maincont3,
  .maincont {

     margin-left: 0;

  }

  .maincont2,
  .maincont4 {

    margin-left: 20px;  
  }

  .subcont,
  .subcont2,
  .subcont3,
  .subcont4 {
    margin-top: 15px;
    font-size: 16px;

  }


  h2.mainformtext {

    font-size: 35px;

  }


}


@media (min-width: 990px) and (max-width: 1200px) {



  .hsmainhd {

    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 0;

  }

  .hsp {

    margin-top: -50px;
    font-size: 20px;
    white-space: nowrap;
  }



  .hssp-t {

    width: 280px;
   margin-top: -6px;
  }



  .abtbtn.btn.btn-primary{
   
    font-size: 20px;
    white-space: nowrap;
    width: 280px;
    height: 42px;
    margin-top: -1px;
    border-radius: 999px;
  }


  .hssp.btn.btn-primary {


    width: 280px;
    height: 42px;
    font-size: 20px;
    margin-top: -10px;
   
  }
   
}



@media (min-width: 1200px) and (max-width: 1500px) {



  .hsmainhd {

    font-size: 42px;
    margin-top: 30px;
    margin-bottom: 0;

  }

  .hssp-t {

    width: 280px;
   
  }

  .hsp {

    margin-top: -40px;
    font-size: 25px;
    white-space: nowrap;
  }


   .hssp.btn {

    margin-top: 5px;
    
   }



  .abtbtn.btn.btn-primary{
   
    font-size: 20px;
    white-space: nowrap;
    width: 280px;
    height: 47px;
    margin-bottom: 10px;
    border-radius: 999px;
  }


  .hssp.btn.btn-primary {


    width: 280px;
    height: 47px;
    font-size: 20px;
    margin-top: -10px;
   
  }
   
}



@media (min-width: 1500px) and (max-width: 1800px) {



  .hsmainhd {

    font-size: 50px;
    margin-top: 30px;
    margin-bottom: 0;

  }



  .hsp {

    margin-top: -25px;
    font-size: 25px;
    white-space: nowrap;
  }


  .hssp-t {

    width: 300px;
   
  }



  .abtbtn.btn.btn-primary{
   
    font-size: 20px;
    white-space: nowrap;
    width: 300px;
    height: 45px;
    margin-bottom: 10px;
    border-radius: 999px;
  }


  .hssp.btn.btn-primary {


    width: 300px;
    height: 45px;
    font-size: 22px;
    margin-top: -10px;
   
   
  }
   
}




@media (min-width: 990px) and (max-width: 1200px) {



  .c-section {

    display: flex;
  }


  .cont {
    display: flex;
    text-align: center;
    margin-top: 25px;

  }



  .maincont,
  .maincont2,
  .maincont3,
  .maincont4 {
    text-align: center;

    font-size: 22px;
  }


  .maincont3,
  .maincont {

     margin-left: 0;

  }

  .maincont2,
  .maincont4 {

    margin-left: 20px;  
  }
  

  .subcont,
  .subcont2,
  .subcont3,
  .subcont4 {
    margin-top: 15px;
    font-size: 18px;

  }


}


@media (min-width: 1200px) and (max-width: 1800px) {



  .maincont {

    margin-left: 0px;
    font-size: 20px;



  }



  .maincont2 {

    font-size: 20px;
    margin-left: 30px;



  }



  .maincont3 {


    font-size: 20px;
    margin-left: 100px;



  }



  .maincont4 {


    font-size: 20px;
    margin-left: 120px;


  }


  .subcont,
  .subcont2,
  .subcont3,
  .subcont4 {

    font-size: 15px;

  }






}



@media (min-width: 1800px) and (max-width: 2400px) {



  .maincont {

    margin-left: -20px;
    font-size: 21px;



  }



  .maincont2 {

    font-size: 21px;
    margin-left: 30px;



  }



  .maincont3 {


    font-size: 21px;
    margin-left: 120px;



  }



  .maincont4 {


    font-size: 21px;
    margin-left: 140px;


  }


  .subcont,
  .subcont2,
  .subcont3,
  .subcont4 {

    font-size: 17px;

  }


}



