
.list {
      
  display: flex;
  flex-direction: column;

  }


  .condhead  {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-size: 35px;
    font-weight: 500;
    

  }

  .maintable  {

    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
 

    
  }

  
  
.patient-container {
    
  
      display: flex;
      flex-direction: column; 
      align-items: center;
      margin-top: 50px;
      color: black;
      margin-bottom: 15px;
      padding: 20px;
    
    }
    


    .patient-row {
      display: flex;
      justify-content: space-between;
      color: black;
      margin-bottom: 20px;
      width: 1200px;
      border-bottom: 1px solid #1bbaaf;
      align-items: center;
      
    }
  
  
    .patient-row1{
      display: flex;
      width: 1200px;
      justify-content: space-between;    
      margin-bottom: 10px;
      border-radius: .5rem;
      
    
    }


    .table>:not(caption)>*>* {

    
    border-bottom-width: 0;

    }

    .patient-name{
    
      display: flex;
      font-size: 25px;
     
      
    
    }
  
    .action-container {
      margin-right: 55px;
    
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    
    }

    .appdatecontainer {

     margin-right: 60px;
      font-size: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      

    }

     
    .patient-namerev {

      font-size: 20px;
      font-weight: 400px;
      width: 150px;
      white-space: nowrap;
    
      
    }

    .patientappdate {

      font-size: 20px;
      width: 150px; 
      

    }

    .actionbtn {
  
      margin-right: 10px;
    
    }
    
  
  .call-btn,
  .msg-btn {
    margin-right: 20px;
    width: 80px;
    height: 30px;
    border-radius: 15px; 
    background-color: #1bbaaf;
    border: none;
    color: #fff; 
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .call-btn:hover,
  .msg-btn:hover {

    background-color: #008a80;
  }


  .iicon {


    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
  }



   .popiicon {

    margin-right: 10px;
   
   }

  .poplink {


    text-decoration: none;
    color: black;
  }

  .poplink:hover {

    color: rgb(93, 93, 93);
  }


    
    .patient-row1:hover {
      cursor: pointer;
    }
    
    
    
    .patient-row td {
      padding: 8px;
    
    }
  
    .patient-row1 td {
      padding: 8px;

    }

  
    .popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5); 
      z-index: 999; 
    }
    
    .popup {
      width:  600px;
      height: 610px;
      background-color: #fff;
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    .pophead  {
      margin-bottom: 30px;
      text-align: center;
      font-size: 40px; 
      color: #1bbaaf;
      margin-top: 20px;
     
    }
    
    


    .fo {

      display: flex;
      flex-direction: row;
      font-size: 20px;
      margin-top: 15px;
      
      
    }

    


    .fp {

      width: 20px;

    }

   .fnme {

    white-space: wrap;
    width: 150px;
    margin-left: 20px;


   }


   .fpp {

    margin-left: 25px;
   width: 350px;
     

   }

   .fmp {

    margin-left: 25px;
   width: 350px;
     

   }

    .clpop {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    
    .cpop {
      
      width: 80px;
      height: 30px;
      margin-top: 20px;
      margin-bottom: 10px;
      border-radius: 999px;
      background-color: #1bbaaf;
      border: #1bbaaf;
      color: #fff;
      
     
    }


   
    .patient-row1:nth-child(even)  {
      background-color: #e7e7e7;
    }
    
   .patient-row1:nth-child(even) .patientappdate  {
      background-color: #e7e7e7;
    }
  
    .patient-row1:nth-child(even) .patient-namerev{

      background-color: #e7e7e7;
    }
    
    .patient-row1:nth-child(even) .actionbtn {

      background-color: #e7e7e7;

    }




    

    .pagination-container {

      
      display: flex;
      margin-bottom: 80px;
      align-items: center;
      justify-content: center; 
    
      
    }
  
    .pbtn{

      border-radius: 999px;
      background-color: #1bbaaf;
      border: #1bbaaf;
      color: #fff;
      margin-right: 10px;
      width: 80px;
      height: 30px;
      font-size: 15px;
    }

    .nbtn{

      border-radius: 999px;
      background-color: #1bbaaf;
      border: #1bbaaf;
      color: #fff;
      margin-left: 10px;
      width: 80px;
      height: 30px;
      font-size: 15px;
    }

    .pagination > li {
      display: inline-block;
      margin-right: 5px;
    }
    
    .pagination > li > a {
      color: #1bbaaf;
      padding: 6px 12px;
      text-decoration: none;
      border: 1px solid #1bbaaf;
      border-radius: 5px;
    }
    
    .pagination > li > a:hover {
      background-color: #1bbaaf;
      color: #fff;
    }
    
    .pagination > .active > a {
      background-color: #1bbaaf;
      color: #fff;
    }
    


    @media (max-width: 300px) {


      
  .list {
      
    
    justify-content: center; 
    align-items: center;
 
}

.condhead  {

 
  font-size: 18px;
  font-weight: 600;
  

}

 
        
      .patient-container {
    
        width: 290px;
      
      }
      
      .patient-row {
      
      
        width: 260px;
       
      
        
      }
    
    
      .patient-row1{
        
        width: 260px;
      
      }
    
    
      .patient-name
      {
       
        font-size: 19px;
        
        
      
      }

      .patient-namerev {

        font-size: 15px;
       

      }
    
     
      .action-container {
        margin-left: 45px;
        font-size: 19px;
      
      }


      .actionbtn {
    
        margin-right: -20px;
      
      }
      
      
    
    
    .call-btn,
    .msg-btn {
      margin-right: 12px;
      width: 45px;
      height: 25px;
      border-radius: 999px; 
    
    }
  
  
      .popup {
       
        width: 250px;
        height: 490px;
      }
      
      

      .pophead {

        justify-content: center;
        font-size: 25px;
        font-weight: 500px;
        margin-top: 20px;

      }

      .cpop {
      
       
        margin-top: -1px;
       
      }

      .fo {

        font-size: 10px;
        font-weight: 500;

      }


      .fp {

        width: 2px;
  
      }
  
     .fnme {
  
      white-space: wrap;
       width: 50px;
    
     }
  
  
     .fpp {
  
    
     width: 80px;
       
  
     }

     .fmp {

      width: 120px;
      min-height: fit-content;
      overflow: hidden;
     }

      
      .popiicon {


        width: 15px;
      }
       
    


      .pagination-container {

      font-size: 15px;
      margin-bottom: 80px;
     
    
        
      }

      .iicon {

        width: 20px;
        height: 15px;
        margin-bottom: 2px;
      }
  

    }


    @media (min-width: 300px) and  (max-width: 500px) {

          
  .list {
      
  
      
    justify-content: center; 
    align-items: center;
  
  
  }

       
.condhead  {

 
  font-size: 21px;
  font-weight: 600;
  

} 



      .patient-container {
    
        width: 350px;
        margin-top: 50px;
        margin-bottom: 15px;
        padding: 20px;
      
      }
      
      .patient-row {
      
        margin-bottom: 20px;
        width: 300px;
        border-bottom: 1px solid #1bbaaf;
      
        
      }
    
    
      .patient-row1{
        
        width: 300px;
          
        margin-bottom: 10px;
        border-radius: .5rem;
        height: 40px;
      
      }
    

      .patient-name
      {
       
        font-size: 17px;
        
        
      
      }

      .patient-namerev {

        font-size: 16px;
       
      
        
      }

    
      
      
    
      .action-container {
        margin-left: 70px;
        font-size: 17px;
      
      }


      .actionbtn {
    
        margin-right: 10px;
      
      }
      
      
    
    
    .call-btn,
    .msg-btn {
      margin-right: 7px;
      width: 55px;
      height: 25px;
      border-radius: 999px; 
    
    }


    .iicon {

      width: 20px;
      height: 17px;
      margin-bottom: 4px;
    }
  
     
      
      .popup {
       
        width: 300px;
        height: 550px;
      }
      
      

      .pophead {

        justify-content: center;
        font-size: 25px;
        font-weight: 500px;

      }

      .fo {

        font-size: 14px;
        font-weight: 500;

      }


      .fmp {

        width: 350px;
        overflow: hidden;
       }
      
     .cpop {

      margin-bottom: 20px;
     }

      .pagination-container {

        margin-bottom: 80px;
     
    
        
      }
  

    }

    
    @media   (min-width: 500px ) and (max-width: 600px) {


          .list {

            justify-content: center;
            align-items: center;
          }

                       
                .condhead  {

                
                  font-size: 23px;
                  font-weight: 600;
                  

                }



             .patient-container {
       
             width: 530px;
             margin-top: 50px;
             margin-bottom: 15px;
             padding: 20px;
           
           }
           
           .patient-row {
           
             margin-bottom: 20px;
             width: 480px;
             border-bottom: 1px solid #1bbaaf;
           
             
           }
         
         
           .patient-row1{
             
             width: 480px;
             height: 40px;
           
           }
     
           .appdatecontainer {
     
             margin-right: -40px;
              font-size: 15px;
             
             
            }
        
        
            .patientappdate {
        
              font-size: 16px;
             
            }
         
         
           .patient-name
           {
            
             font-size: 15px;
             
             
           
           }
     
           .patient-namerev {
     
             font-size: 16px;
            
           
             
           }
         
           .action-container {
             margin-left: 50px;
             font-size: 15px;
           
           }
     
     
           .actionbtn {
         
             margin-right: 10px;
           
           }
           
           
         
         
         .call-btn,
         .msg-btn {
           margin-right: 20px;
           width: 55px;
           height: 25px;
           border-radius: 999px; 
         
         }      


         .iicon {

          width: 20px;
          height: 17px;
          margin-bottom: 4px;
        }
           
         .popup {
            
           width: 480px;
           height: 530px;
         }
         
         
     
         .pophead {
     
           
           font-size: 30px;
           font-weight: 700px;
           margin-top: 10px;
     
         }
     
         .fo {
     
           font-size: 17px;
     
         }
         
     
     
           .pagination-container {
     
             margin-bottom: 80px;
          
         
             
           }
       
     
         }


   






    @media   (min-width: 600px ) and (max-width: 740px) {


                                      
              .condhead  {

              
                font-size: 27px;
                font-weight: 600;
                

              }
                  
             .patient-container {
       
             width: 530px;
             margin-top: 50px;
             margin-bottom: 15px;
             padding: 20px;
           
           }
           
           .patient-row {
           
             margin-bottom: 20px;
             width: 480px;
             border-bottom: 1px solid #1bbaaf;
           
             
           }
         
         
           .patient-row1{
             
             width: 480px;
             height: 40px;
           
           }
     
           .appdatecontainer {
     
             margin-right: -60px;
              font-size: 17px;
             
            }
        
        
            .patientappdate {
        
              font-size: 16px;
             
            }
         
         
           .patient-name
           {
            
             font-size: 17px;
             
             
           
           }
     
           .patient-namerev {
     
             font-size: 16px;
            
           
             
           }
         
           .action-container {
             margin-left: 50px;
             font-size: 17px;
           
           }
     
     
           .actionbtn {
         
             margin-right: 10px;
           
           }
           
           
         
         
         .call-btn,
         .msg-btn {
           margin-right: 20px;
           width: 55px;
           height: 25px;
           border-radius: 999px; 
         
         }      
           


         .iicon {

          width: 20px;
          height: 18px;
          margin-bottom: 4px;
        }



         .popup {
            
           width: 480px;
           height: 530px;
         }
         
         
     
         .pophead {
     
           
           font-size: 30px;
           font-weight: 700px;
           margin-top: 10px;
     
         }
     
         .fo {
     
           font-size: 17px;
     
         }
         
     
     
           .pagination-container {
     
             margin-bottom: 80px;
          
         
             
           }
       
     
         }

         @media   (min-width: 740px ) and (max-width: 940px) {



          .condhead  {

              
            font-size: 27px;
            font-weight: 600;
            

          }

          .patient-container {
    
          width: 680px;
          margin-top: 50px;
          margin-bottom: 15px;
          padding: 20px;
        
        }
        
        .patient-row {
        
          margin-bottom: 20px;
          width: 630px;
          border-bottom: 1px solid #1bbaaf;
        
          
        }
      
      
        .patient-row1{
          
          width: 630px;
          height: 40px;
        
        }
  
        .appdatecontainer {
  
          margin-right: -60px;
           font-size: 17px;
          
         }
     
     
         .patientappdate {
     
           font-size: 16px;
          
         }
      
      
        .patient-name
        {
         
          font-size: 17px;
          
          
        
        }
  
        .patient-namerev {
  
          font-size: 16px;
         
        
          
        }
      
        .action-container {
          margin-left: 50px;
          font-size: 17px;
        
        }
  
  
        .actionbtn {
      
          margin-right: 10px;
        
        }
        
        
      
      
      .call-btn,
      .msg-btn {
        margin-right: 20px;
        width: 55px;
        height: 25px;
        border-radius: 999px; 
      
      }      


      
      .iicon {

        width: 20px;
        height: 18px;
        margin-bottom: 4px;
      }

      .pagination-container {
     
        margin-bottom: 80px;
     
    
        
      }

    }


    @media   (min-width: 940px ) and (max-width: 1300px) {


      .condhead  {

              
        font-size: 35px;
        font-weight: 600;
        

      }

      .patient-container {
    
        width: 930px;
        margin-top: 50px;
        margin-bottom: 15px;
        padding: 20px;
      
      }
      
      .patient-row {
      
        margin-bottom: 20px;
        width: 880px;
        border-bottom: 1px solid #1bbaaf;
      
        
      }
    
    
      .patient-row1{
        
        width: 880px;
        height: 40px;
      
      }
    
      .appdatecontainer {

       
         font-size: 20px;
        
       }
   
   
       .patientappdate {
   
         font-size: 19px;
        
       }
    
      .patient-name
      {
       
        font-size: 20px;
        
        
      
      }

      .patient-namerev {

        font-size: 19px;
       
      
        
      }
    
      .action-container {
        
        font-size: 20px;
        margin-right: 45px;
      
      }


      .actionbtn {
    
        margin-right: 10px;
      
      }
      
      
    
    
    .call-btn,
    .msg-btn {
      margin-right: 20px;
      width: 55px;
      height: 25px;
      border-radius: 999px; 
    
    }

    
    .iicon {

      width: 20px;
      height: 19px;
      margin-bottom: 5px;
    }
      
    .popup {
       
      width: 530px;
      height: 560px;
    }
    
    

    .pophead {

     
      font-size: 35px;
      font-weight: 800px;
      margin-top: 10px;

    }

    .fo {

      font-size: 18px;

    }
    

      .pagination-container {

        margin-bottom: 80px;
      
     
    
        
      }
  

    }

    @media (max-width: 600px) {

      .pagination-container {

        margin-left: 0;
      
      }


      .maintable {

        margin-left: 0;
      }
    }

    @media (min-width: 1600px) and (max-width:1900px) {


    .patient-row {
     
      width: 1400px;
     
    }
  
  
    .patient-row1{
     
      width: 1400px;

      }

    }