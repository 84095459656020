   
  
   
   .straight-teeth-section {
    background: #1bbaaf;
      padding: 25px;
      color: white;
      font-family: 'Montserrat', sans-serif,Arial;
    }

    .straight-teeth-content {  
      display: flex;
     
      
    }


    .main-strat {

      font-size: 35px;
      margin-right: 290px;
    }

      .strat {

        font-weight: 400;
        font-size: 24px;

        
      }

      .b.text-nowrap{

        font-size: 25px;
        font-weight: 700;
        width: 330px;
        height: 60px;
        border-radius: 999px;
      }

      .mb-3 {

        font-weight: 700;
      }
      .left-side {
        margin-bottom: 0px;
      }

      .right-side {
        margin-top: 20px;
      }



    
      @media (max-width: 767px) {
        .straight-teeth-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      
        .left-side {
          margin-bottom: 20px;
        }
      
        .right-side {
          margin-top: 0;
        }
      
        .main-strat {
          font-size: 28px;
          font-weight: 700;
          justify-content: center;
          align-items: center;
          margin: 0; 
        }
      
        .strat {
          font-weight: 400;
          font-size: 18px;
          margin: 0; 
        }
      
        .b.text-nowrap {
          display: flex;
          font-weight: 700;
          font-size: 20px;
          width: 265px;
          height: 34px;
          justify-content: center;
          align-items: center;
        }
      }
      


    @media (min-width: 767px) and (max-width: 1024px) {

     
      
      .straight-teeth-content {
        display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
      
      }

      .main-strat {
      
        font-size: 35px;
        margin: 0; 
      }
      
      .strat {
      
        font-weight: 400;
        font-size: 25px;
        margin-top: 40px;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0; 
        
      }
      
      .b.text-nowrap{
      
        font-size: 23px;
        font-weight: 700;
        width: 300px;
        height: 50px;
        border-radius: 999px;
        margin-top: 30px;
        
      }
      .mb-3 {
      
        font-weight: 700;
      }
      .left-side {
        margin-bottom: 0px;
      }
      
      .right-side {
        margin-top: 20px;
      }


     
    }


    @media (min-width: 1024px) and (max-width: 1400px) {


      .main-strat {

        font-size: 25px;
        white-space: nowrap;
        margin-right: 160px;

      }

      .strat{

        font-size: 18px;
      }

      .b.text-nowrap{

        font-size: 22px;
        font-weight: 700;
        width: 300px;
        height: 60px;
        border-radius: 999px;

      }





    }