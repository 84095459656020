      
      .mn-container {
        display: flex;
        height: 100vh;
      }

      .logsection {

        background: linear-gradient(to bottom, #1e7fa8, #1bbaaf);



        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        font-family: Montserrat, sans-serif, Arial;
        height: 100vh;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .log-container {

        display: flex;
        flex-direction: column;
       position: relative;

        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 450px;
        height: 530px;
        z-index: 999;
      }

      .log {
        width: 95px;
        height: 95px;
        margin-bottom: 20px;
      }



      .fm-control {
        margin-top: 30px;
       

       
        color: #666;
        line-height: 1.2;
        display: block;
        height: 40px;
        width: 320px;
       
        background: 0 0;
      

      }

      .fm-control:focus {

          
          box-shadow: none; 
           border: 1px solid #1bbaaf;

      }



      .log-form .fm-control{

      border: none;
      border-bottom: 2px solid #ccc;
      border-radius: 1px;
      padding-left: 60px;
    
      

      }

      
      input.fm-control{


        margin-top: 40px;
        font-size: 20px;


      }



      .input-icon {
        position: absolute;
        top: 60%;
        left: 68px;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 24px;
        z-index: 1;
        
        
      }


      .inpt-icon {
        position: absolute;
        top: 46%;
        left: 68px;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 24px;
        z-index: 1;
      }


      .btn.log {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        color: white;
        background-color:#1bbaaf ;  
        border: none;
        border-radius: 999px;
        width: 320px;
        height: 45px;
        font-size: 20px;
       

      }

      .btn.log:hover {

        background-color: #008a80;
        color: #fff;
      }

       .pfor {


       
        text-align: right;
        font-size: 18px;
        margin-top: 25px;
        text-decoration: none;
      }

      .pff {

        text-decoration: none;
        color: #666;
        margin-right: 20px;
      }
      
      
      .error-container {
        position: relative;
        width: 100%;
        margin-bottom: 30px;
      }
      
      .error-message {
        position: absolute;
        top: 30%;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 21px;
        font-weight: 500;
        z-index: 6;
        color: red;
        font-family: 'Roboto';
      }

         


      @media (max-width:500px) {


        .log-container {
       
          width: 250px;
          height: 420px;
         
        }
  
        .log {
          width: 60px;
          height: 60px;
          margin-bottom: 10px;
        }
  
        .fm-control {
         
          height: 40px;
          width: 200px;
  
        }

        .btn.log {
         
          width: 200px;
          height: 32px;
          font-size: 16px;

  
        }
  
        .pfor {
  
          font-size: 12px;
       
        }


        .input-icon {
          position: absolute;
          top: 60%;
          left: 35px;
          transform: translateY(-50%);
          pointer-events: none;
          font-size: 20px;
          z-index: 1;
          
          
        }
  
  
        .inpt-icon {
          position: absolute;
          top: 42%;
          left: 35px;
          transform: translateY(-50%);
          pointer-events: none;
          font-size: 20px;
          z-index: 1;
        }

        .error-message {
          
          font-size: 16px;
         
        }
  

      }

      @media (min-width: 500px) and (max-width: 1500px) {


        .log-container {

          
          padding: 20px;
          width: 380px;
          height: 480px;
      
        }
  
        .log {
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
        }
  
  
  
        .fm-control {
          margin-top: 30px;
          height: 40px;
          width: 290px;
      
        }

       .input-icon {
          position: absolute;
          top: 59%;
          left: 48px;
          transform: translateY(-50%);
          
        }
  
  
        .inpt-icon {
          position: absolute;
          top: 43%;
          left: 48px;
          transform: translateY(-50%);

        }
  
  
        .btn.log {
        
          margin-top: 40px;
          width: 290px;
          height: 40px;
          font-size: 19px;
         
  
        }
  
      
  
         .pfor {
  
  
         
          text-align: right;
          font-size: 13px;
          margin-top: 19px;
          text-decoration: none;
        }
  
        
        
        .error-container {
         
          margin-bottom: 30px;
        }
        
        .error-message {
          position: absolute;
          top: 30%;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 16px;
        
        }
  




      }