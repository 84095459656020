
  
  .main-container {
    
    background: linear-gradient(to bottom, #1e7fa8, #1bbaaf);

    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .white-box {
    background-color: white;
    width: 450px;
    height: 350px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
 
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .pswhead {

    margin-top: 20px;
  }
 

  .form-contr{


    width : 250px;
    margin-top: 30px;


  }


   .form-contr:focus {

    
    box-shadow: none; 
    border: 1px solid #1bbaaf;
   }
  
  .resetbtn {


    width : 250px;
    margin-top: 30px;
    border-radius: 999px;
    padding: 10px 20px;
    background-color:#1bbaaf;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .resetbtn:hover {
    background-color: #008a80;
    color: #fff;
  }


  .pback {


       
   
    font-size: 15px;
    margin-top: 25px;
    text-decoration: none;
  }

  .pbb {

    text-decoration: none;
    color: black;
    
  }
  
  
  .alertpsw {

    height: 20px;
    position: absolute;
    top: 42%;
    right: 1;
    width: 250px; 
   

  }

  @media ( max-width: 500px ) {


    .white-box {
     
      width: 300px;
      height: 330px;
     
    }


  }