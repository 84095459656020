.contact-section.full-width {
  max-width: 100%;
  padding: 0px;
  font-family: Montserrat, sans-serif, Arial;

}

.contact-section {
  background: #1bbaaf;

  font-weight: 550;
  letter-spacing: 0.25px;

}

.justify-content-center {
  justify-content: center;
}

.section-row {
  padding-top: 20px;
  padding-bottom: 30px;
}

.map-marker {
  font-size: 24px;
}


.envelope {
  font-size: 16px;
  margin-right: 4px;

}



.logo {

  color: white;
  margin-top: 15px;
  margin-bottom: 10px;
}

.new-line1 {
  padding: 5px;
  margin-bottom: 10px;
  color: white;
  font-size: 23px;


}


.new-line3 {
  padding: 5px;
  margin-bottom: 13px;
  color: white;
  font-size: 23px;


}

.new-line2 {
  padding: 5px;

  margin-bottom: 20px;
  color: white;
  font-size: 23px;

}



.line1 {

  padding: 5px;
  margin-bottom: 0;
  color: white;
  font-size: 16px;

}

.map-container {

  padding: 5px;
  margin-bottom: 10px;
  color: white;
  font-size: 6px;
  width: 180px;
  height: 150px;
  margin-left: 120px;

}
.social-icon {

  margin-top: 10px;
  margin-right: 25px;
  width: 25px;
  height: 25px;
  color: white;
}



.line2 {
  line-height: 1;
  padding: 5px;
  margin-bottom: 10px;
  color: white;
  font-size: 16px;
  margin-left: 23px;

}

.line3 {
  line-height: 0.2;
  padding: 5px;
  margin-bottom: 0px;
  color: white;
  font-size: 16px;
  white-space: nowrap;

}

.line31 {
  line-height: 0.2;
  padding: 5px;
  margin-bottom: 0px;
  color: white;
  font-size: 16px;
  white-space: nowrap;

}

.contact-link {
  color: white;
  text-decoration: none;

}





.text-center {
  text-align: center;
}

@media (max-width: 400px) {

  .new-line1,

  .new-line3 {

    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 10px;

  }

  .new-line2 {

    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0px;



  }


  .line1,
  .line2,
  .line3 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 11px;


  }


  .line31 {

    font-size: 11px;
    margin-top: -15px;


  }


  .social-icon {


    width: 22px;
    height: 22px;

  }
}



@media (min-width: 400px) and (max-width: 768px) {

  .new-line1,

  .new-line3 {

    font-size: 19px;
    margin-bottom: 15px;
    margin-top: 10px;

  }

  .new-line2 {

    font-size: 19px;
    margin-top: 5px;
    margin-bottom: 0px;



  }


  .line1,
  .line2,
  .line3 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 13px;

  }

  .line2 {

    margin-left: 30px;
  }

  .line31 {

    font-size: 12px;
     margin-top: -18px;



  }


  .social-icon {


    width: 20px;
    height: 20px;

  }
}


@media (min-width: 768px) and (max-width: 800px) {


  .new-line1 {

    font-size: 18px;
    margin-top: 10px;

  }


  .new-line3 {

    font-size: 18px;
    
    margin-top: -11px;

  }

  .new-line2 {


    font-size: 18px;
    margin-top: -10px;
    margin-bottom: 20px;



  }


  .line1,
  .line2 {

    margin-top: 10px;
    font-size: 11px;
    



  }


  .line3 {

    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 11px;

  }

  .line31 {

    font-size: 12px;
    margin-top: -10px;


  }


  .social-icon {


    width: 22px;
    height: 22px;
    margin-right: 20px;

  }

}




@media (min-width: 800px) and (max-width: 1100px) {


  .new-line1 {

    font-size: 18px;
    margin-top: 10px;

  }


  .new-line3 {

    font-size: 18px;
    
    margin-top: 19px;

  }

  .new-line2 {


    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 20px;



  }


  .line1,
  .line2 {

    margin-top: 10px;
    font-size: 11px;
    
  }

  .line2 {

    margin-left: 35px;
  }

  .line3 {

    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 11px;

  }

  .line31 {

    font-size: 12px;
    margin-top: -10px;


  }


  .social-icon {


    width: 20px;
    height: 20px;

  }

}